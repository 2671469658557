<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.datos_laborales') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >

      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form2_persona.UY.salario')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              v-model="formulario2.ingreso_mensual"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.salario')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.profesion"
            :label="$t('forms_flujo.form2_persona.BNF.profesion')"
            :error="errors.get('profesion')"
          >
            <el-input
              v-model="formulario2.info_extra.profesion"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.BNF.profesion')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.cargo_ocupa"
            :label="$t('forms_flujo.form2_persona.UY.cargo_ocupa')"
            :error="errors.get('tipo_tienda')"
          >
            <el-input
                    v-model="formulario2.info_extra.cargo_ocupa"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.cargo_ocupa')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form2_persona.UY.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              v-model="formulario2.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form2_persona.UY.fecha_ingreso')"
            />
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.empresa_trabajo"
                  :label="$t('forms_flujo.form2_persona.UY.empresa_trabajo')"
                  :error="errors.get('info_extra.empresa_trabajo')"
          >
            <el-input
                    ref="info_extra.empresa_trabajo"
                    v-model="formulario2.info_extra.empresa_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.empresa_trabajo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="banco_deposito"
                  :label="$t('forms_flujo.form2_persona.UY.banco_deposito')"
                  :error="errors.get('banco_deposito')"
          >
            <el-select
                    ref="banco_deposito"
                    v-model="formulario2.banco_deposito"
                    clearable
                    :placeholder="$t('forms_flujo.form2_persona.UY.banco_deposito')"
            >
              <el-option
                      v-for="item in bancos"
                      :key="item.id"
                      :label="item.short_name"
                      :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="info_extra.tipo_cuenta"
                  :label="$t('forms_flujo.form2_persona.UY.tipo_cuenta')"
                  :error="errors.get('info_extra.tipo_cuenta')"
          >
            <el-select
                    ref="info_extra.tipo_cuenta"
                    v-model="formulario2.info_extra.tipo_cuenta"
                    clearable
                    :placeholder="$t('forms_flujo.form2_persona.UY.tipo_cuenta')"
            >
              <el-option
                      v-for="item in tipo_cuenta"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="cuenta_deposito"
                  :label="$t('forms_flujo.form2_persona.UY.cuenta_deposito')"
                  :error="errors.get('cuenta_deposito')"
          >
            <el-input
                    ref="cuenta_deposito"
                    v-model="formulario2.cuenta_deposito"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form2_persona.UY.cuenta_deposito')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <pz-direction
          v-model="formulario2.direccion"
          name-direction
      />
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_empresa"
            :label="$t('forms_flujo.form2_persona.UY.telefono_empresa')"
            :error="errors.get('telefono_empresa')"
          >
            <el-input
              v-model="formulario2.telefono_empresa"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.telefono_empresa')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <hr class="pz-dotted">
      <el-row
              :gutter="20"
              class="pz-large-margin"
      >
        <el-col
                :span="24"
                class="row justify-content-center"
        >
          <b class="pz-font-size-lg">
            {{ $t('forms_flujo.form2_persona.UY.referencias') }}
          </b>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="familiar[0].nombre_completo"
                  :label="$t('forms_flujo.form2_persona.UY.familiar.nombre_completo')"
                  :error="errors.get('familiar[0].nombre_completo')"
          >
            <el-input
                    v-model="formulario2.familiar[0].nombre_completo"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.familiar.nombre_completo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="familiar[0].telefono"
                  :label="$t('forms_flujo.form2_persona.UY.personal.telefono')"
                  :error="errors.get('familiar[0].telefono')"
          >
            <el-input
                    v-model="formulario2.familiar[0].telefono"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.personal.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="familiar[1].nombre_completo"
                  :label="$t('forms_flujo.form2_persona.UY.familiar.nombre_completo')"
                  :error="errors.get('familiar[1].nombre_completo')"
          >
            <el-input
                    v-model="formulario2.familiar[1].nombre_completo"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.familiar.nombre_completo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="familiar[1].telefono"
                  :label="$t('forms_flujo.form2_persona.UY.familiar.telefono')"
                  :error="errors.get('familiar[1].telefono')"
          >
            <el-input
                    v-model="formulario2.familiar[1].telefono"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.familiar.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="personal[0].nombre_completo"
                  :label="$t('forms_flujo.form2_persona.UY.personal.nombre_completo')"
                  :error="errors.get('personal[0].nombre_completo')"
          >
            <el-input
                    v-model="formulario2.personal[0].nombre_completo"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.personal.nombre_completo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
        >
          <el-form-item
                  prop="personal[0].telefono"
                  :label="$t('forms_flujo.form2_persona.UY.personal.telefono')"
                  :error="errors.get('personal[0].telefono')"
          >
            <el-input
                    v-model="formulario2.personal[0].telefono"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.personal.telefono')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {getBancos } from "@/routes/api/resources";
    import {required} from "@/commons/utils/Rules";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    import {mapGetters} from "vuex";
    export default {
        name: 'PzPersonFormExtImpacta2',
        components: {PzFormFlujo,PzDirection},
        mixins: [baseform2],
        data() {
            return {
                bancos: [],
                tipo_cuenta: [
                    {id: 'ahorros', name: 'Ahorros'},
                    {id: 'monetaria', name: 'Monetaria'}
                ],
                formulario2: {
                    ingreso_mensual:'',
                    fecha_ingreso:'',
                    banco_deposito:'',
                    cuenta_deposito:'',
                    telefono_empresa:'',
                    familiar:[{
                        nombre_completo:'',
                        telefono:'',
                    },{
                        nombre_completo:'',
                        telefono:'',
                    }],
                    personal:[{
                        nombre_completo:'',
                        telefono:'',
                    }],
                    info_extra: {
                        profesion: '',
                        cargo_ocupa: '',
                        empresa_trabajo: '',
                        tipo_cuenta: '',
                    },
                    direccion: {},

                },
                rules_formulario2:
                    {
                        ingreso_mensual:[required('')],
                        fecha_ingreso:[required('')],
                        banco_deposito:[required('')],
                        cuenta_deposito:[required('')],
                        telefono_empresa:[required('')],
                        info_extra: {
                            profesion: [required('')],
                            cargo_ocupa: [required('')],
                            empresa_trabajo: [required('')],
                            tipo_cuenta: [required('')],
                        },
                        familiar:[{
                            nombre_completo:[required('')],
                            telefono:[required('')],
                        },
                          {
                              nombre_completo:[required('')],
                              telefono:[required('')],
                          }],
                        personal:[{
                            nombre_completo:[required('')],
                            telefono:[required('')],
                        }],
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return Date.now() < time.getTime();
                    },
                },
            };
        },
        computed: {
            ...mapGetters(['applicant']),
        },
        created: function () {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let bancos = getBancos().then((res) => {
                this.bancos = res.data;
            }).catch(() => {
                this.bancos = [];
            });
            return Promise.all([ bancos]).then(() => {
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
        methods: {},
    }
</script>